import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { SplashScreen } from 'src/components/loading-screen';
import AuthBackgroundLayout from 'src/layouts/auth/background';

// ----------------------------------------------------------------------

const RegisterBackgroundPage = lazy(() => import('src/pages/auth/register-background'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'jetzt-bewerben',
        element: (
          <AuthBackgroundLayout>
            <RegisterBackgroundPage />
          </AuthBackgroundLayout>
        ),
      },
      
    ],
  },
];
