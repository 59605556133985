import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import { bgBlur } from 'src/theme/css';
import Logo from 'src/components/logo_black_midi';
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
import { useOffSetTop } from 'src/hooks/use-off-set-top';

import { HEADER } from '../config-layout';

import HeaderShadow from './header-shadow';

// ----------------------------------------------------------------------

export default function HeaderSimple() {
  const theme = useTheme();

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          
          ...(offsetTop
            ? {
                bgcolor: 'background.paper', // Change background color to white when scrolled
                boxShadow: theme.shadows[4], // Add shadow for better visibility
                height: {
                  md: HEADER.H_DESKTOP_OFFSET,
                },
              }
            : {
                ...bgBlur({
                  color: theme.palette.background.default, // Keep original blur effect when not scrolled
                }),
              }),
        }}
      >
        <Logo />

        <Stack direction="row" alignItems="center" spacing={1}>
          <Link
            href={paths.agentur.jobs}
            component={RouterLink}
            color="inherit"
            sx={{ typography: 'subtitle2' }}
          >
            Alle Stellenangebote
          </Link>
        </Stack>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
